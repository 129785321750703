// Dependency
import React from "react"
import { graphql } from "gatsby"
import { useStoryblokState } from "gatsby-source-storyblok"
import Blogpost from "../components/general/blog-post/blog-post"

const BlogPost = ({ data }) => {
  let story = data.storyblokEntry
  story = useStoryblokState(story)

  return (
    <>
      <Blogpost story={story} date={data.storyblokEntry.created_at} />
    </>
  )
}

export default BlogPost

export { Head } from "./page"

export const query = graphql`
  query ListingQuery($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      ...StoryEntry
      created_at
      first_published_at
    }
  }
`
