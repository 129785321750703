// Dependency
import React from "react"
import Image from "../../ui/image/image"
import Link from "../../ui/link/link"
import { graphql, useStaticQuery } from "gatsby"
import BlogListCard from "../blog-listing-card/blog-list-card"
import Breadcrumbs from "../breadcrumbs/breadcrumbs"

// Helper
import RichTextResolver from "../../../helpers/storyblok/richTextResolver"

//Style
import "./blog-post.scss"
import { storyblokEditable } from "gatsby-source-storyblok"
import { useTranslation } from "react-i18next"

// Template
const Blogpost = props => {
  const componentProps = props.story.content
  const query = useStaticQuery(graphql`
    query {
      story: allStoryblokEntry(
        filter: { field_component: { eq: "bloglisting" } }
        sort: { fields: name }
      ) {
        nodes {
          name
          slug
          content
          full_slug
          created_at
          first_published_at
        }
      }
    }
  `)
  const { t } = useTranslation()
  const lang = process.env.GATSBY_BUILD_LANGUAGE
  let filterLang = lang === "en" ? "default" : lang
  let filteredData
  if (filterLang === "default") {
    filteredData =
      query.story.nodes.filter(node => node.full_slug.startsWith("blog/")) ??
      query.story.nodes[0]
  } else {
    filteredData =
      query.story.nodes.filter(node =>
        node.full_slug.includes(`${filterLang}/`)
      ) ?? query.story.nodes[0]
  }

  //const recentPosts = JSON.parse(filteredData[0].content).blocks

  const links = [{ title: t("blog.blog"), link: "blog" }]
  return (
    <div
      {...storyblokEditable(props.story.content)}
      key={props.story.content._uid}
    >
      <div className="blog-post">
      <div className="listing__breadcrumbs">
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <Breadcrumbs 
               links={links} 
               breadTitle={componentProps.title} 
              large={true}
            />
            </div>
          </div>
        </div>
      </div>
        <Image
          largeScreenImage={componentProps.largeScreenImage.filename}
          largeScreenHeight={440}
          largeScreenWidth={1400}
          smallScreenImage={componentProps.smallScreenImage.filename}
          smallScreenHeight={269}
          smallScreenWidth={375}
          alt={componentProps.largeScreenImage.alt}
          layout={"constrained"}
        />
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              <div className="blog-post__inner">
                <h1 className="blog-post__title">{componentProps.title}</h1>
                {/* <div className="blog-post__date">
                  {FormattedDate(props.story.first_published_at)}
                </div> */}
                <RichTextResolver
                  data={componentProps.content}
                  styleClass="blog-post__content"
                />
                {/* <div className="blog-post__date">
                  {FormattedDate(props.story.first_published_at)}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="blog-post__recent-posts">
          {recentPosts.length > 0 && (
            <div className="grid-container">
              <div className="blog-post__recent-posts-heading">
                {t("blog.recentposts")}
              </div>
              <div className="grid__row">
                {recentPosts.map((blog, index) => {
                  return (
                    <div
                      key={index}
                      className="grid__col-xlarge-4 grid__col-large-4 grid__col-medium-9 grid__col-small-2"
                    >
                      <Link
                        linkText={<BlogListCard props={blog} />}
                        linkUrl={`/${blog.fullSlug}`}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  )
}

export default Blogpost
